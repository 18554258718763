@font-face {
    font-family: "Untitled Sans";
    font-weight: 400;
    src:
      local("Untitled Sans"),
      url('/assets/fonts/UntitledSansWeb-Regular.woff2') format("woff2"),
      url('/assets/fonts/UntitledSansTest-Regular.otf') format("opentype");
  }

  @font-face {
    font-family: "Untitled Sans";
    font-weight: 600;
    src:
      local("Untitled Sans"),
      url('/assets/fonts/test-untitled-sans-bold.woff2') format("woff2"),
      url('/assets/fonts/TestUntitledSans-Bold.otf') format("opentype");
  }

:root{
    --primary-color: #0031AF;
    --margin_x: 40px;
    --margin_y: 28px;
    --gap: 20px;
    --spacer-lg: 160px; 
    // --mobile-nav-height: 293px;
    --mobile-nav-height: 320px;
}

html{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-size: 20px;
}

body{
    font-family: Untitled Sans, sans-serif;
    letter-spacing: -0.04em;
    margin:0;
    overflow-x:hidden;
}

a{
    color: black;
    text-decoration: none;
}

a:hover,
button:hover{
    color: var(--primary-color);
}

button{
    background:inherit;
    border:inherit;
    padding:inherit;
    font-size:inherit;
    font-family: inherit;
    letter-spacing:inherit;
    cursor:pointer;
}

ul{
    margin:0;
    padding:0;
}

li{
    list-style-type:none;
}

h1,h2,h3,h4,h5,h6{
    margin:0;
}


// GLOBAL TEXT STYLES

.s1{
    font-weight: 400;
    font-size: 5.5rem;
    line-height: 0.93;
    letter-spacing: -0.07em;
}

.s2{
    font-weight: 400;
    font-size: 4rem;
    line-height: 97.9%;
    letter-spacing: -0.07em;
}

.s3{
    font-weight: 400;
    font-size: 3rem;
    line-height: 97.9%;
    letter-spacing: -0.07em;
}

.s4{
    font-weight: 400;
    font-size: 2rem;
    line-height: 112.9%;
    letter-spacing: -0.07em;
    /* hanging-punctuation is currently only supported in Safari. */
    hanging-punctuation: first last;
}

.s5{
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 112%;
    letter-spacing: -0.06em;
}

.s6{
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 112.9%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    margin-bottom: 16px;
}

p, li{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: -0.06em;
    margin-top:0;
}

.small{
    font-size: 0.7rem;
    line-height: 17px;
    letter-spacing: -0.03em;
}


// COLOR

.accent{
    color: var(--primary-color);
}


// GLOBAL ELEMENT STYLES

.arrow{
    font-family: 'Inter';
}

.beads{
    display: flex;
    gap: 15px;
    align-items:center;
}

.bead{
    width: 7px;
    height: 7px;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
}

.bead.active,
.bead:hover{
    background: var(--primary-color);
}

.hangline{
    border-top: 1px solid var(--primary-color);
    padding-top: 16px;
}

.border-x4{
    border: 1px solid var(--primary-color);
}

.img-x2{
    width: 100%;
    display:flex;
    img{
        width: 50%;
    }
}

.link{
    border-bottom: 1px solid var(--primary-color);
    svg{
        margin-left: 10px;
        transition: margin 300ms;
    }

}

.link:hover{
    svg{
        margin-left: 20px;
    }
}


// NAVIGATION

nav{
    font-size: 0.7rem;
    padding: var(--margin_y) var(--margin_x);
    padding-bottom:0px;
    display: flex;
    align-items:flex-start;
    border-bottom: 1px solid white;
    position:sticky;
    top:0;left:0;
    width: calc(100% - var(--margin_x)*2);
    z-index:2;
    background: white;
    transition: top 300ms;
    .logo{
        font-weight: 600;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 250px;
        transform:translateY(-6.5px);
        span{
            display:block;
            line-height: 0.93;
        }
        path{
            fill: black;
        }
    }
    .logo:hover{
        color: black;
    }
    .main-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .main-nav > ul{
        display: flex;
        gap: 40px;
        li{
            font-size: 0.7rem;
            letter-spacing: -0.04em;
            line-height: normal;
        }
        a, button{
            color: var(--primary-color);
        }
    }
    .log-in{
        margin-right: 130px;
        padding: 8px 20px;
        border: 1px solid black;
        // margin-top: 2em;
        display: block;
        color: var(--primary-color);
        border-color: var(--primary-color);

    }
    .log-in:hover{
        background: var(--primary-color);
        color: white;

    }

    .dropdown{
        display: none;
        margin-top: 1em;
        margin-bottom:1em;
        width: 10px;
        overflow:visible;
        white-space: nowrap;

        *:nth-child(4){
            margin-top: 1em;
        }
    }
    .cta{
        position: fixed;
        right:var(--margin_x);
        background: black;
        color: white;
        padding: 9px 20px;
        border: 1px solid rgba(255,255,255);
        margin-right: -1px;
        top:calc(2em - 1px);
    }
    .cta:hover{
        background: var(--primary-color);
    }
    .menu-icon{
        display:none;
        width: 34px;
        height: 28px;
        padding:0;
        background: white;
        border-top: 1px solid var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        position:absolute;
        top:27px;
        // margin-left: auto;
        // margin-right: 120px;
        // margin-top:3px;
        // margin-left: calc(100vw - var(--margin_x) - 175px);
    }
    .menu-icon:before{
        content: '';
        display:block;
        height: 50%;
        border-bottom: 1px solid var(--primary-color);
        width: 100%;
        top: 0;
        position:absolute;
    }
}

@media screen and (max-width: 700px){
    nav{
        font-size: 12.6px;
        // height: 40px;
        .logo{
            // position:absolute;
        }
        .main-nav{
            position: absolute;
            left: var(--margin_x);
        }
        .menu-icon{
            display:block;
            right: 172px;
            // right:162px;
            top:0;
        }
        .main-nav > ul, .log-in{
            display:none;
        }
        .main-nav > ul li{
            font-size: 1rem;
            letter-spacing: -0.06em;
            font-size: 12.6px;
        }
        .nav-btns{
            position:absolute;
            top:0;
            left:0;
            width:100vw;
            height: 100%;
        }
        .log-in{
            margin-bottom: 1em;
            font-size: 1rem;
            letter-spacing: -0.06em;
            font-size: 12.6px;
        }
    }
    nav.open{
        // > ul{
        //     padding-top:60px;
        // }
        height: var(--mobile-nav-height);
        ul{
            display:block;
            z-index: 2;
            position: relative;
            
            li{
                margin-bottom: 1em;
            }
        }
        ul.dropdown li{
            margin-bottom: 0em;
            margin-top:0;
        }
        .main-nav{
            display: block;
            padding-top:60px;
        }
        .log-in{
            display: inline-block;
            position: absolute;
            bottom: 0;
            transform: translateY(calc(100% + 1em));
            // margin-top:1em;
        }
    }
    
}

@media screen and (max-width: 640px){
    nav{
        .menu-icon{
            right:162px;
        }
    }
}

nav:before{
    content: '';
    width: 100%;
    background: var(--primary-color);
    height: 1px;
    position: absolute;
    left:0px;
    top: 17px;
}

nav.open{
    border-bottom: 1px solid var(--primary-color);
    .dropdown{
        display: block;
    }

}

nav + script + * {
    margin-top: 60px;
}


nav.sticky{
    background: white;
    border-bottom: 1px solid #e2e2e2;
}




// LAYOUT

section{
    padding: 0 var(--margin_x);
}

.cols{
    display:flex;
    gap: var(--gap);
    *{
        flex: 1;
    }
    .cols-x2{
        flex: 2;
    }
}




// FOOTER

footer{
    padding: 0 var(--margin_x);
    margin-top: var(--spacer-lg);
    overflow:hidden;
    *{
        font-size: 0.7rem;
        line-height: 1.2;
    }
    .footer-body{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height: 340px;
        
    }
    #footer_interactive{
        width: 100%;
        height: 340px;
        background: var(--primary-color);
    }
    .mobile-placeholder{
        display:none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media screen and (max-width: 700px){
    footer .footer-body{
        height: 400px;
        .cols{
            flex-wrap: wrap;
            > *{
                flex-basis: calc(50% - 10px);
                order:1;
            }
            > *:first-child{
                flex-basis: 100%;
                margin-bottom: 40px;
            }
            > *:nth-child(2){
                margin-bottom: 20px;
            }
            > *:nth-child(4){
                margin-bottom: 40px;
            }
            li a, a{
                font-size: 12.6px;
            }
            // > *:nth-child(4){
            //     order: 3;
            // }
            // > *:nth-child(5){
            //     order: 4;
            // }
        }
    }
    footer{

        canvas{
            display:none;
        }
        #footer_interactive{
            background-image: url('/assets/img/dust-placeholder.png');
            background-position:center;
            background-size: cover;
            background-position:center;
        }
        #footer_interactive.mobile{
            background-image: url('/assets/img/footer-gif.gif');
        }
    }
}



// sector CARDS

.section-sector-cards{
    margin-bottom: 100px;
    .sector-cards{
        display:flex;
        width: 100%;
        gap: var(--gap);
        flex-wrap: wrap;
        > *{
            flex-basis: calc(33.333333% - var(--gap)*2/3);
            margin-bottom: 50px;
        }
        > *:hover{
            .image-hover img:nth-child(2){
                visibility:hidden;
            }
        }
        .image-hover{
            width:100%;
            height: calc(33.333333vw - var(--gap)*2/3);
            position:relative;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }
        .s4{
            margin-bottom:10px;
        }
    }
}

@media screen and (max-width: 900px){
    .section-sector-cards .sector-cards{
        > *{
            flex-basis: calc(50% - var(--gap)*1/2);
            margin-bottom: 50px;
        }
        .s4{
            font-size: 1.5rem;
        }
        p{
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 650px){
    .section-sector-cards .sector-cards{
        > *{
            margin-bottom: 40px;
        }
    }
}

.insights{
    display:flex;
    gap: var(--gap);
    width: 100%;
    flex-wrap:wrap;
    a{
        --padding: 15px;
        border: 1px solid var(--primary-color);
        padding: var(--padding);
        flex-basis: calc(50% - var(--gap)/2 - 2px - var(--padding)*2);
        min-height: 18vw;
        div{
            max-width: 550px;
        }
        h3{
            margin-bottom: 20px;
        }
    }
    a:hover{
        background: var(--primary-color);
        color: white;
    }
}

@media screen and (max-width: 700px){
    .insights{
        display: block;
        a{
            display:block;
            margin-bottom: 20px;
        }
    }

}


// CTA SECTION

#section_cta{
    padding-bottom: 100px;
    padding-top: 100px;
    .cta{
        
        margin-top: 50px;
        a{
            background-color: var(--primary-color);
            color: white;
            padding: 12px 16px;
            svg {
                margin-left: 10px;
                path{
                fill: white;
                }
            }
        }
    }
  }



  .primary-btn{
        background-color: var(--primary-color);
        color: white;
        padding: 12px 16px;
        border: 1px solid transparent;
        svg {
            margin-left: 10px;
            path{
            fill: white;
            }
        }
}

.primary-btn:hover{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background: white;
    svg {
        path{
        fill: var(--primary-color);
        }
    }
}

@media screen and (max-width: 1040px){
   html{
    font-size: 18px;
   }
   nav .log-in{
    margin-right: 115px;
    margin-top: -0.2em;
   }
}

@media screen and (max-width: 800px){
    :root{
        --margin_x: 30px;
    }
    .s1{
        font-size: 4rem;
    }
    nav {
        gap: 20px;
        .logo{
            width: auto;
            margin-right: 20px;
        }
    }
    p{
        line-height: 1.3;
    }
 }

@media screen and (max-width: 640px){
    :root{
        --margin_x: 25px;
        --gap: 15px;
    }
    html{
     font-size: 16px;
    }
    body{
        overflow-x: hidden;
    }
 }